<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="账号/姓名" prop='name'>
          <a-input v-model="params.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="用户状态" prop='state'>
          <a-select
            v-model="params.state"
            placeholder="请选择"
            @change="changeStateSearch"
            style="min-width: 120px"
          >
            <a-select-option value=''>全部</a-select-option>
            <a-select-option value="1">有效</a-select-option>
            <a-select-option value="2">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="角色" prop ='shopRole'>
          <a-select
            @change="changeRoalSearch"
            v-model="params.shopRole"
            placeholder="请选择"
            style="min-width: 120px"
          >
            <a-select-option value=''>全部</a-select-option>
            <a-select-option
              v-for="item in roleSelectOptions"
              :key="item.shopRoleId"
              :value="item.shopRoleId"
            >{{item.roleName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="addStaff" v-pms="'add'">添加员工</a-button>
    </div>
    <base-table
      bordered
      :columns="columns"
      :dataSource="dataRef"
      :pagination="pageRtv"
      :rowKey="record => record.shopStaffId"
      :loading="loadingRef"
      @change="handleTableChange">
      <template slot="loginName" slot-scope="text,record">
        <div class="role-name-box">
          <div>{{text}}</div>
          <div v-if="record.masterFlag" class="official-flag">店主</div>
        </div>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" v-pms="'view'" @click="viewStaff(record.shopStaffId)">查看</a-button>
        <a-button
          type="link"
          v-pms="'edit'"
          v-if="record.shopStaffId !== currentShopStaffId && !record.masterFlag"
          @click="editStaff(record.shopStaffId)"
        >编辑</a-button>
        <a-popconfirm
          v-if="record.shopStaffId !== currentShopStaffId && !record.masterFlag"
          placement="topRight"
          title="确定要删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="()=>confirmDelete(record.shopStaffId)"
        >
          <a-button type="link" v-pms="'delete'">删除</a-button>
        </a-popconfirm>
        <a-button type="link"  @click="getStaffPoster(record.shopStaffId)">推广二维码</a-button>
      </div>
    </base-table>
    <a-modal
      :maskClosable="false"
      okText="保存"
      :confirmLoading="dialogSubmitBtnLoading"
      v-model="dialogVisiable"
      :title="dialogTitle"
      width="580px"
      @ok='onSubmit'
    >
      <a-spin :spinning="formLoading">
        <a-form-model
          ref="dialogFormRef"
          :model="formData"
          :rules="!formDisabled?rules:{}"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :onCancel="clickDialogCLoseBtn"
        >
          <a-form-model-item label="员工账号：" prop="loginName">
            <a-input
              v-if="!formDisabled"
              v-model="formData.loginName"
              allowClear
              placeholder="员工账号"
            />
            <div v-if="formDisabled">{{formData.loginName}}</div>
          </a-form-model-item>
          <a-form-model-item label="员工姓名：" prop="realName">
            <a-input
              v-if="!formDisabled"
              v-model="formData.realName"
              allowClear
              placeholder="员工姓名"
            />
            <div v-if="formDisabled">{{formData.realName}}</div>
          </a-form-model-item>
          <a-form-model-item label="员工联系方式：" prop="phone">
            <a-input v-if="!formDisabled" v-model="formData.phone" allowClear placeholder="员工联系方式" />
            <div v-if="formDisabled">{{formData.phone}}</div>
          </a-form-model-item>
          <a-form-model-item label="店铺角色" prop="shopRoleId">
            <a-row type="flex">
              <a-col :span="18" v-if="!formDisabled">
                <a-select v-model="formData.shopRoleId" placeholder="店铺角色" style="min-width: 120px">
                  <a-select-option
                    v-for="item in roleSelectOptions"
                    :key="item.shopRoleId"
                    :value="item.shopRoleId"
                  >{{item.roleName}}</a-select-option>
                </a-select>
              </a-col>
              <div
                v-if="formDisabled"
              >{{roleSelectOptions.find(item=>item.shopRoleId ===formData.shopRoleId)?roleSelectOptions.find(item=>item.shopRoleId ===formData.shopRoleId).roleName:''}}</div>
              <a-col :span="6">
                <a-popover placement="right" trigger="click" v-if="formData.shopRoleId">
                  <div slot="content" style="max-height:500px;overflow:auto;min-width:200px;">
                    <a-spin :spinning="dialogTreeLoading">
                      <a-tree
                        v-if="treeData.length"
                        showIcon
                        :replaceFields="replaceFields"
                        defaultExpandAll
                        :selectable="false"
                        :tree-data="treeData"
                      >
                        <template slot="page" slot-scope="row">
                          <icon-svg :icon-class="row.icon||'icon-page'" />
                        </template>
                        <template slot="btn">
                          <icon-svg icon-class="icon-btn" />
                        </template>
                      </a-tree>
                      <no-data v-else />
                    </a-spin>
                  </div>
                  <a-button type="link" @click="getTreeByRole">功能预览</a-button>
                </a-popover>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="是否启用：" prop="state">
            <a-radio-group v-if="!formDisabled" name="radioGroup" v-model="formData.state">
              <a-radio :value="1">启用</a-radio>
              <a-radio :value="2">禁用</a-radio>
            </a-radio-group>
            <div v-if="formDisabled">
              <span v-if="formData.state === 1">启用</span>
              <span v-if="formData.state === 2">禁用</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="数据权限" prop="shopIds" v-if="checkBoxOptions.length">
            <div v-if="!formDisabled">
              <a-checkbox
                :indeterminate="indeterminate"
                :checked="checkAll"
                @change="onCheckAllChange"
              >全选</a-checkbox>
            </div>
            <a-checkbox-group
              v-if="!formDisabled"
              v-model="formData.shopIds"
              :options="checkBoxOptions"
              @change="selectSupplier"
            />
            <div v-if="formDisabled">{{shopsNames}}</div>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
    <a-modal
      v-model="postModalShow"
      :width="375"
      :closable="false"
      :title="null"
      class="post-img-modal"
      :footer="null"
    >
      <div class="post-img-box">
        <img v-if="postImg" :src="postImg" alt class="post-img" />
        <p v-else>二维码生成失败</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { staff, system, common } from '@/api'
import { SHOPID, SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
import { phoneValidate } from '@/utils/validator'
import NoData from '@/components/NoData'
const columns = [
  {
    title: '登录账号',
    dataIndex: 'loginName',
    scopedSlots: { customRender: 'loginName' }
  },
  {
    title: '员工姓名',
    dataIndex: 'realName',
    customRender: x => {
      return x || '-'
    }
  },
  {
    title: '联系方式',
    dataIndex: 'phone',
    customRender: x => {
      return x || '-'
    }
  },
  {
    title: '所属角色',
    dataIndex: 'roleName'
  },
  {
    title: '添加人',
    dataIndex: 'createUser',
    customRender: x => {
      return x || '-'
    }
  },
  {
    title: '是否启用',
    dataIndex: 'state',
    customRender: x => {
      return x === 1 ? '启用' : '禁用'
    }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: 300,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'PageStaffManagement',
  components: {
    NoData
  },
  setup (props, { root }) {
    const state = reactive({
      params: {
        shopId: getSession(SHOPID),
        name: '',
        state: '',
        shopRole: ''
      },
      dialogVisiable: false,
      dialogTitle: '添加员工',
      formRef: null,
      roleSelectOptions: [],
      selectStaffId: '',
      formLoading: false,
      dialogFormRef: null,
      formData: {
        state: 1,
        shopIds: []
      },
      indeterminate: false,
      baseUrl: process.env.VUE_APP_IMG,
      checkAll: false,
      checkBoxOptions: [],
      dialogTreeLoading: false,
      dialogSubmitBtnLoading: false,
      treeData: [],
      formDisabled: false,
      shopsNames: '',
      postModalShow: false,
      postImg: ''
    })
    const currentShopStaffId = getSession(SHOPSTAFFID)
    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }
    const replaceFields = { children: 'children', title: 'name', key: 'id' }
    const rules = {
      realName: [
        { required: true, message: '请填写员工姓名', trigger: 'change' },
        { max: 16, message: '请尝试简单一点的姓名', trigger: 'change' }
      ],
      loginName: [
        { required: true, message: '请填写员工账号', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' }
      ],
      loginPwd: [{ required: true, message: '请填密码', trigger: 'change' }],
      phone: [
        { required: true, message: '请填写员工联系方式', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' }
      ],
      shopRoleId: [
        { required: true, message: '请选择店铺角色', trigger: 'change' }
      ]
    }
    const {
      dataRef,
      pageRtv,
      loadingRef,
      setPage,
      resetPage,
      refresh
    } = useTableList(staff.getStaffList, ref(state.params))

    getRoleOPtions()
    getMySuppler()
    watch(
      () => state.dialogVisiable,
      (newVal, oldVal) => {
        if (!newVal) {
          state.dialogFormRef.resetFields()
          state.checkAll = false
          state.indeterminate = false
        }
      }
    )
    async function getMySuppler () {
      let { code, msg, data } = await common.getListPartner(0, false)
      if (code === '00000') {
        state.checkBoxOptions = data.map(item => {
          return {
            label: item.name,
            value: item.shopId
          }
        })
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }

    async function getRoleOPtions () {
      let { code, msg, data } = await staff.getRoleListSelect({
        shopId: getSession(SHOPID)
      })
      if (code === '00000') {
        state.roleSelectOptions = data
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }
    function clickDialogCLoseBtn () {
      state.dialogFormRef.resetFields()
    }
    function handleTableChange ({ current }) {
      setPage(current)
    }

    function handleSearch () {
      resetPage()
      refresh()
    }

    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }

    async function confirmDelete (shopRoleId) {
      let { code, msg } = await staff.deleteStaff({
        shopStaffId: shopRoleId
      })
      if (code === '00000') {
        root.$message.success('删除成功')
        refresh()
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }

    function addStaff () {
      state.formData = {
        state: 1,
        shopIds: []
      }
      state.formRef.resetFields()
      state.dialogVisiable = true
      state.dialogTitle = '添加员工'
      state.selectStaffId = ''
      state.formDisabled = false
    }

    function viewStaff (id) {
      state.dialogVisiable = true
      state.dialogTitle = '员工详情'
      state.selectStaffId = id
      state.formDisabled = true
      state.shopsNames = ''
      getStaffDetail()
    }

    function editStaff (id) {
      state.dialogVisiable = true
      state.dialogTitle = '修改员工'
      state.selectStaffId = id
      state.formDisabled = false
      getStaffDetail()
    }

    async function getStaffDetail () {
      state.formLoading = true
      let { code, msg, data } = await staff.getStaffDetail({
        shopStaffId: state.selectStaffId,
        shopId: getSession(SHOPID)
      })
      state.formLoading = false
      if (code === '00000') {
        let param = {
          loginName: data.loginName,
          phone: data.phone,
          realName: data.realName,
          shopRoleId: data.shopRoleId,
          state: data.state,
          shopIds: data.shops
        }
        for (let key in param) {
          root.$set(state.formData, key, param[key])
        }
        if (
          data.shops.length &&
          data.shops.length < state.checkBoxOptions.length
        ) {
          state.indeterminate = true
        }
        let arr = []
        state.checkBoxOptions.forEach(item => {
          if (data.shops.indexOf(item.value) !== -1) {
            arr.push(item.label)
          }
        })
        state.shopsNames = arr.join(',')
        if (
          data.shops.length &&
          data.shops.length >= state.checkBoxOptions.length
        ) {
          state.checkAll = true
        }
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }

    function selectSupplier (checkedList) {
      if (
        checkedList.length &&
        checkedList.length < state.checkBoxOptions.length
      ) {
        state.indeterminate = true
      } else {
        state.indeterminate = false
      }
      state.checkAll = checkedList.length === state.checkBoxOptions.length
    }
    function onCheckAllChange (e) {
      state.formData.shopIds = e.target.checked
        ? state.checkBoxOptions.map(item => item.value)
        : []
      state.indeterminate = false
      state.checkAll = e.target.checked
    }

    function onSubmit () {
      state.dialogFormRef.validate(async valid => {
        if (valid) {
          state.dialogSubmitBtnLoading = true
          let param = Object.assign(
            { shopId: getSession(SHOPID) },
            state.formData
          )
          param.shopIds = param.shopIds.join(',')
          if (state.selectStaffId) {
            param.shopStaffId = state.selectStaffId
          }
          let { code, msg } = await staff.saveStaff(param)
          state.dialogSubmitBtnLoading = false
          if (code === '00000') {
            root.$message.success(state.dialogTitle + '成功')
            state.dialogVisiable = false
            refresh()
          } else {
            root.$message.error(msg || '添加失败')
          }
        }
      })
    }

    async function getTreeByRole () {
      state.treeData = []
      state.dialogTreeLoading = true
      let { code, data, msg } = await system.getAllMenus({
        shopRoleId: state.formData.shopRoleId
      })
      state.dialogTreeLoading = false
      if (code === '00000') {
        handleIconSoltDeep(data)
        state.treeData = data
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }
    function handleIconSoltDeep (arr) {
      arr.forEach(item => {
        if (!item.icon) {
          item.icon = ''
        }
        item.scopedSlots = { icon: item.type === 1 ? 'page' : 'btn' }
        if (item.children && item.children.length) {
          handleIconSoltDeep(item.children)
        }
      })
    }
    function changeStateSearch (e) {
      root.$set(state.params, 'state', e)
      handleSearch()
    }

    function changeRoalSearch (e) {
      root.$set(state.params, 'shopRole', e)
      handleSearch()
    }

    async function getStaffPoster (staffId) {
      const { data, msg, code } = await system.getStaffPoster({
        shopStaffId: staffId
      })
      state.getPostImgLoading = false
      if (code === '00000') {
        showPostImgModal(state.baseUrl + data)
      } else {
        root.$message.error(msg || '获取海报图片失败')
      }
    }

    function showPostImgModal (data) {
      state.postImg = data
      state.postModalShow = true
    }

    return {
      ...toRefs(state),
      rules,
      wrapperCol,
      replaceFields,
      labelCol,
      viewStaff,
      editStaff,
      addStaff,
      columns,
      dataRef,
      pageRtv,
      loadingRef,
      confirmDelete,
      handleReset,
      handleSearch,
      changeRoalSearch,
      handleTableChange,
      getRoleOPtions,
      changeStateSearch,
      currentShopStaffId,
      selectSupplier,
      onCheckAllChange,
      getMySuppler,
      onSubmit,
      getTreeByRole,
      handleIconSoltDeep,
      clickDialogCLoseBtn,
      getStaffPoster,
      showPostImgModal
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-foot {
  padding-top:18px;
  margin-top:18px;
  border-top:1px solid #ddd;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
.role-name-box {
  display: flex;
  align-items: center;
  .official-flag {
    background: @main-color;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 6px;
    font-weight: bolder;
    flex-shrink: 0;
  }
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
.post-img-modal ::v-deep .ant-modal-body {
  padding: 0;
}
.post-img {
  width: 100%;
  object-fit: contain;
}
</style>
